import { FluentContext } from "@fluent/react/esm/context"
import { useContext } from "react"

type UseLocalizedStringParams = {
  id: string
  fallback?: string
  attrs?: Record<string, string>
}

export function useLocalizedString({
  id,
  fallback,
  attrs,
}: UseLocalizedStringParams) {
  const i18n = useContext(FluentContext)
  return i18n?.getString(id, attrs, fallback)
}
