import { Button } from "@ariakit/react"
import { type ReactNode, useState } from "react"

import styles from "./detailEditor.module.css"

import sharedStyles from "@/components/shared.module.css"
import { useLocalizedString } from "@/hooks/useLocalizedString.ts"
import { Subheader } from "@/ui/Subheader/subheader.tsx"

/**
 * Props for the InstitutionNameEditor component.
 */
interface InstitutionNameEditorProps {
  title?: string
  body: string
  handleUpdate: (value: string) => void
  children?: React.ReactNode
}

/**
 * Component for the aside section to edit an attribute of an entity.
 */
export function DetailEditor({
  title,
  body,
  handleUpdate,
  children,
}: InstitutionNameEditorProps): JSX.Element {
  const [value, setValue] = useState(body)
  const [editable, setEditable] = useState(false)

  const saveLabel = useLocalizedString({ id: "save", fallback: "Save" })
  const editLabel = useLocalizedString({ id: "edit", fallback: "Edit" })
  const buttonLabel = editable ? saveLabel : editLabel
  const buttonOnClick = editable
    ? () => {
        handleUpdate(value)
        setEditable(false)
      }
    : () => setEditable(true)

  return (
    <div className={styles["wrapper"]}>
      <Subheader
        text={title}
        buttons={
          <Button
            className={sharedStyles["textButton"]}
            onClick={buttonOnClick}
          >
            {buttonLabel}
          </Button>
        }
      >
        {children}
      </Subheader>
      {editable ? (
        <textarea
          className={styles["inputArea"]}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      ) : (
        <p className={styles["displayText"]}>{body}</p>
      )}
    </div>
  )
}

export function DetailStatic({
  title,
  body,
  emptyPlaceholder,
  children,
}: {
  title: string
  body: string
  emptyPlaceholder?: string
  children?: ReactNode
}): JSX.Element {
  return (
    <div className={styles["wrapper"]}>
      <Subheader text={title}>{children}</Subheader>
      {body ? (
        <p className={styles["displayText"]}>{body}</p>
      ) : (
        <p className={styles["displayTextPlaceholder"]}>
          {emptyPlaceholder || "No data"}
        </p>
      )}
    </div>
  )
}
